//Reach out to EyeNet or similar and see if they want to do a story on it.  Tell them why it is good and where we go from here.

// TODO
// add ability to share (for viral coefficient)
// add email to give feedback
// add video link to see workflow
// fix npm warnings
// make QR send data to glasses retailer

import React, {Component} from 'react';
import {QRCodeSVG} from 'qrcode.react';


import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import './App.css';
import FormInput from './components/FormInput';
import Glasses from './components/Glasses';
import MonoVisionR from './components/MonoVisionR';
import MonoVisionL from './components/MonoVisionL';
import ContactsR from './components/ContactsR';
import ContactsL from './components/ContactsL';
import ContactSearchR from './components/ContactSearchR';
import ContactSearchL from './components/ContactSearchL';
import PatientRx from './components/PatientRx';


class App extends Component {
constructor(){
  super();
  this.state={
    powerR: '',
    cylinderR: '',
    axisR: '',
    powerL: '',
    cylinderL: '',
    axisL: '',
    addPower:'',
    pd: '',
    vertexDistance: '12',

    // powerR: '-1.50',
    // cylinderR: '+0.75',
    // axisR: '059',
    // powerL: '-2.25',
    // cylinderL: '+1.50',
    // axisL: '081',
    // addPower:'+2.50',
    // pd: '59',

    contactBrandR:'',
    contactBrandL:'',
    baseCurveR: '',
    baseCurveL: '',
    contactPickedR: '',
    contactPickedL: '',
    searchStringR: '',
    searchStringL: '',
    activeOptionR: '',
    activeOptionL: '',
    filteredOptionsR: [],
    filteredOptionsL: [],
    showOptionsR: false,
    showOptionsL: false,
    monoVisionR: false,
    monoVisionL: false,
    copyButton: false,
  }
}

handleChange = (e) => this.setState({[e.target.name]:e.target.value})

handleMonoChangeR = () => {
  let monoVisionR = !this.state.monoVisionR;
  let monoVisionL = this.state.monoVisionL;
    (monoVisionL === true)
      ? this.setState ({
        monoVisionL: false,
        monoVisionR: true,
      })
      : this.setState ({monoVisionR: monoVisionR})
}

handleMonoChangeL = () => {
  let monoVisionL = !this.state.monoVisionL;
  let monoVisionR = this.state.monoVisionR;
    (monoVisionR === true)
      ? this.setState ({
        monoVisionR: false,
        monoVisionL: true,
      })
      : this.setState ({monoVisionL: monoVisionL})
}
//___________________CONTACT SEARCH L FUNCTIONS__________________________________
handleClickItemL = c => this.setState({
  searchStringL: "",
  contactPickedL: c,
});

handleContactClickL = c => (
  this.setState({
    contactPickedL: c,
    searchStringL: c.name,
    activeOptionL: 0,
    filteredOptionsL: [],
    showOptionsL: false,
  })
)

handleUserInputL = e => {
  const searchStringL = e.currentTarget.value;

  const filteredOptions = contactOptions.filter((c) =>
    c.name.toLowerCase().indexOf(searchStringL.toLowerCase()) > -1
);

searchStringL.length === 0
?  this.setState({
    searchStringL: '',
    contactPickedL: '',
    activeOptionL: 0,
    filteredOptionsL: filteredOptions,
    showOptionsL: false,
  })
: this.setState({
    searchStringL: e.currentTarget.value,
    activeOptionL: 0,
    filteredOptionsL: filteredOptions,
    showOptionsL: true,
  });
}

handleKeyDownL = (e) => {
  const { activeOptionL, filteredOptionsL} = this.state;

  if (e.keyCode === 13) {
    (filteredOptionsL[activeOptionL] === undefined)
      ? alert("Please check spelling and choose a contact lens from the list")
      : this.setState({
          activeOptionL: 0,
          showOptionsL: false,
          searchStringL: filteredOptionsL[activeOptionL].name,
          contactPickedL: filteredOptionsL[activeOptionL],
      });
  } else if (e.keyCode === 38) {
    if (activeOptionL === 0) {
      return;
    }
    this.setState({activeOptionL: activeOptionL -1});
  } else if (e.keyCode === 40) {
    if (activeOptionL === filteredOptionsL.length -1) {
      return;
    }
    this.setState({activeOptionL: activeOptionL +1});
  }
};

//_____CONTACT SEARCH R FUNCTIONS__________________________________
handleClickItemR = c => this.setState({
  searchStringR: "",
  contactPickedR: c,
});

handleContactClickR = c => (
  this.setState({
    contactPickedR: c,
    searchStringR: c.name,
    activeOptionR: 0,
    filteredOptionsR: [],
    showOptionsR: false,
  })
)

handleUserInputR = e => {
  const searchStringR = e.currentTarget.value;

  const filteredOptions = contactOptions.filter((c) =>
    c.name.toLowerCase().indexOf(searchStringR.toLowerCase()) > -1
);

searchStringR.length === 0
?  this.setState({
    searchStringR: '',
    contactPickedR: '',
    activeOptionR: 0,
    filteredOptionsR: filteredOptions,
    showOptionsR: false,
  })
: this.setState({
    searchStringR: e.currentTarget.value,
    activeOptionR: 0,
    filteredOptionsR: filteredOptions,
    showOptionsR: true,
  });
}

handleKeyDownR = (e) => {
  const { activeOptionR, filteredOptionsR} = this.state;

  if (e.keyCode === 13) {
    (filteredOptionsR[activeOptionR] === undefined)
      ? alert("Please check spelling and choose a contact lens from the list")
      : this.setState({
          activeOptionR: 0,
          showOptionsR: false,
          searchStringR: filteredOptionsR[activeOptionR].name,
          contactPickedR: filteredOptionsR[activeOptionR],
      });
  } else if (e.keyCode === 38) {
    if (activeOptionR === 0) {
      return;
    }
    this.setState({activeOptionR: activeOptionR -1});
  } else if (e.keyCode === 40) {
    if (activeOptionR === filteredOptionsR.length -1) {
      return;
    }
    this.setState({activeOptionR: activeOptionR +1});
  }
};

//___________________________________________________________

handleCopy = () => {
    let copyText = document.querySelector("#finalOutput").innerText;
    let middleText = copyText.replace(/\r?\n/gm, '');
    let contactsText = middleText.replace(/Contacts/gm, '\nContacts');
    let rightText = contactsText.replace(/Right:/gm, '\nRight:');
    let leftText = rightText.replace(/Left:/gm, '\nLeft:\xa0\xa0');
    let addText = leftText.replace(/Add:/gm, '\nAdd:\xa0\xa0');
    let distanceText = addText.replace(/Distance/gm, '\n\nDistance');
    let bifocalText = distanceText.replace(/Bifocal/gm, '\n\nBifocal');
    let glassesText = bifocalText.replace(/Glasses/gm, '\n\nGlasses');
    let printText = glassesText.replace(/Pupillary/gm, '\n\nPupillary');

    if (navigator.clipboard) { // default: modern asynchronous API
      return navigator.clipboard.writeText(printText);
    } else if (window.clipboardData && window.clipboardData.setData) {     // for IE11
      window.clipboardData.setData('Text', printText);
      return Promise.resolve();
    } else {
      // workaround: create dummy input
      const input = ('input', { type: 'text' });
      input.value = printText;
      document.body.append(input);
      input.focus();
      input.select();
      document.execCommand('copy');
      input.remove();
      return Promise.resolve();
    }
  }


  handleButtonAnimation = (e) => {
    e.preventDefault()

    if(this.state.copyButton===false) {
        this.setState({copyButton: !this.state.copyButton});
        setTimeout(function(){
             this.setState({copyButton:false});
        }.bind(this),125);  // wait 0.5 seconds, then reset to false
   }

  }



  //___________________________________________________________



render() {

  let copyButtonStyle =
    (this.state.copyButton===true)
    ? {
        fontSize: '2em',
        width: '153px',
        height: '63px',
        textDecoration: 'none',
        background: '#007bff',
        borderRadius: '5px',
        color: '#fff',
        border: '1px solid #007bff',
        cursor: 'pointer',
      }
    : {
        fontSize: '2em',
        width: '150px',
        height: '60px',
        textDecoration: 'none',
        background: '#007bff',
        borderRadius: '5px',
        color: '#fff',
        border: '1px solid #007bff',
        cursor: 'pointer',
      };


  return (
      <BrowserRouter>
        <Routes>
          <Route path="/:id" element={<PatientRx/>}/>
          <Route path="/" element={
            <div
              style={{
                margin: '7vh 7vw 7vh 7vw',
                width: '86vw'
              }}>
              <FormInput
              powerR={this.state.powerR}
              cylinderR={this.state.cylinderR}
              axisR={this.state.axisR}
              powerL={this.state.powerL}
              cylinderL={this.state.cylinderL}
              axisL={this.state.axisL}
              pd={this.state.pd}
              vertexDistance={this.state.vertexDistance}
              addPower={this.state.addPower}
              onChange={this.handleChange}
            />

              <div style={{
                width: '100%',
              }}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'spaceBetween',
                }}>
                  <div style= {{
                      margin: '50px 0px 0px 0px',
                      textAlign: 'left',
                      position: 'relative',
                    }}>
                    <h2>CONTACT LENS NAME</h2>
                    <ContactSearchR
                      onContactClick={this.handleContactClickR}
                      onKeyDown={this.handleKeyDownR}
                      onUserInput={this.handleUserInputR}
                      searchString={this.state.searchStringR}
                      activeOption={this.state.activeOptionR}
                      filteredOptions={this.state.filteredOptionsR}
                      showOptions={this.state.showOptionsR}
                      />

                    <ContactSearchL
                      onContactClick={this.handleContactClickL}
                      onKeyDown={this.handleKeyDownL}
                      onUserInput={this.handleUserInputL}
                      searchString={this.state.searchStringL}
                      activeOption={this.state.activeOptionL}
                      filteredOptions={this.state.filteredOptionsL}
                      showOptions={this.state.showOptionsL}
                      />
                  </div>

                  <div style= {{
                      margin: '50px 0px 0px 0px',
                      textAlign: 'left',
                      position: 'relative',
                    }}>
                    <h2 style= {{
                        textAlign: 'right',
                        padding: '0 0 0 100px'
                      }}>MONOVISION</h2>
                    <MonoVisionR
                        monovision = {this.state.monoVisionR}
                        onMonoChange = {this.handleMonoChangeR}
                      />
                    <MonoVisionL
                        monovision = {this.state.monoVisionL}
                        onMonoChange = {this.handleMonoChangeL}
                      />

                  </div>
                </div>
             </div>
              <div style={{
                  width: 'auto',
                  border: '1px solid gray',
                  borderRadius: '20px',
                  margin: '50px 0 200px 80px',
                  padding: '10px',
                }}>
                <div style={{
                   display: 'flex',
                   justifyContent: 'space-between',
                   margin: '0 0 20px 0',
                }}>
                  <h1 style={{
                    margin: '10px 10px 10px 20px',
                  }}>Prescription:</h1>

                  <button
                    style = {copyButtonStyle}
                    type="button"
                    name="button"
                    id='copyButton'
                    onClick={(e) => {
                        this.handleCopy();
                        this.handleButtonAnimation(e);
                      }
                    }
                  >
                    Copy
                  </button>

                </div>
                <div
                  style={{
                    margin: '0 0 0px 80px'
                    }}
                  id="finalOutput"
                  >
                  {this.state.contactPickedR !== '' || this.state.contactPickedL !== ''
                    ?
                    <div>
                      <p style={{
                          textAlign: 'left',
                          fontWeight: '700',
                          margin: '0',
                        }}>Contacts
                      </p>
                      <ContactsR
                        power={this.state.powerR.toLowerCase().replace(/\s+/gm,'')}
                        cyl={this.state.cylinderR.toLowerCase().replace(/\s+/gm,'')}
                        axis={this.state.axisR.toLowerCase()}
                        addPower={this.state.addPower.toLowerCase()}
                        vertexDistance={this.state.vertexDistance}
                        monovision={this.state.monoVisionR}
                        contactBrand={this.state.contactBrandR}
                        baseCurve={this.state.baseCurveR}
                        contactPicked={this.state.contactPickedR}
                        onChange={this.handleChange}
                      />
                      <ContactsL
                        power={this.state.powerL.toLowerCase().replace(/\s+/gm,'')}
                        cyl={this.state.cylinderL.toLowerCase().replace(/\s+/gm,'')}
                        axis={this.state.axisL.toLowerCase()}
                        addPower={this.state.addPower.toLowerCase()}
                        vertexDistance={this.state.vertexDistance}
                        monovision={this.state.monoVisionL}
                        contactBrand={this.state.contactBrandL}
                        baseCurve={this.state.baseCurveL}
                        contactPicked={this.state.contactPickedL}
                        onChange={this.handleChange}
                      />
                    </div>
                    : <div></div>
                  }


                  <Glasses
                    powerR={this.state.powerR.toLowerCase().replace(/\s+/gm,'')}
                    cylinderR={this.state.cylinderR.toLowerCase().replace(/\s+/gm,'')}
                    axisR={this.state.axisR.toLowerCase()}
                    powerL={this.state.powerL.toLowerCase().replace(/\s+/gm,'')}
                    cylinderL={this.state.cylinderL.toLowerCase().replace(/\s+/gm,'')}
                    axisL={this.state.axisL.toLowerCase()}
                    addPower={this.state.addPower.toLowerCase()}
                    pd={this.state.pd.toLowerCase()}
                  />
                  {this.state.pd === ''
                    ? <div></div>
                    :
                      <div>
                        <p style={{
                            margin: '15px 0 50px 0'
                          }}>
                          <strong>Pupillary distance </strong>: {this.state.pd} mm
                        </p>

                      </div>
                  }
                </div>
                {this.state.pd === ''
                  ? <div></div>
                  :
                    <div style ={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '0 40px 70px 40px',
                        background: 'rgb(242, 243, 244)',
                        borderRadius: '30px',
                      }}>
                      <div
                        style={{
                          width: '33%',
                          textAlign: 'center',
                          borderRadius: '20px',
                          display:'block',
                        }}>
                        <p
                          style={{
                            fontSize: '24px',
                            textAlign:'center',
                          }}
                          >1: PRESCRIPTION
                        </p>
                        <div>
                          <img
                            src='./glasses.png'
                            alt='glasses'
                            style={{
                              width: '100px',
                              textAlign:'center',
                              margin: '20px 0 0 0',
                            }}>
                          </img>
                        </div>

                        <QRCodeSVG
                          style={{
                            margin: '10px 0 0 0'
                            }}
                          value={`https://refractionconverter.herokuapp.com/${this.state.powerR}&${this.state.cylinderR}&${this.state.axisR}&${this.state.powerL}&${this.state.cylinderL}&${this.state.axisL}&${this.state.addPower}&${this.state.pd}`}
                        />
                      </div>
                      <div
                        style={{
                          width: '33%',
                          textAlign: 'center',
                          padding: '0 0 50px 0',
                          borderRadius: '20px',
                        }}>
                        <p
                          style={{
                            fontSize: '24px',
                            textAlign:'center',
                          }}
                          >2: SCAN IT</p>
                        <img
                          src='./qrIphone.png'
                          alt='qr code'
                          style={{height: '300px'}}>
                        </img>
                      </div>
                      <div
                        style={{
                          width: '33%',
                          textAlign: 'center',
                          padding: '0 0 50px 0',
                          borderRadius: '20px',
                        }}>
                        <p
                          style={{
                            fontSize: '24px',
                            textAlign:'center',
                          }}
                          >3: FIND GLASSES</p>
                        <img
                          src='./rxIphone.png'
                          alt='prescription'
                          style={{height: '300px'}}>
                        </img>
                      </div>


                    </div>
                  }
                </div>
              </div>
            }/>
        </Routes>
      </BrowserRouter>
  );
}
}
export default App;

const contactOptions = [
        //ALCON LENSES
        {
          type: 'spherical',
          brand: 'Alcon',
          name: 'Air Optix plus HydraGlyde',
          bc: '8.6',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-8',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Alcon',
          name: 'Air Optix plus HydraGlyde for Astigmatism',
          bc: '8.7',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-10', pMax: '6', cMin: '-2.25', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
           ],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Alcon',
          name: 'Air Optix plus HydraGlyde Multifocal',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '3',
            value1: 'Lo',
            value2: 'Med',
            value3: 'Hi',
          },
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Alcon',
          name: 'Air Optix Colors',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-8',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Alcon',
          name: 'Air Optix Night & Day Aqua (BC 8.4)',
          bc: '8.4',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '-8',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Alcon',
          name: 'Air Optix Night & Day Aqua (BC 8.6)',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '-8',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Alcon',
          name: 'DAILIES AquaComfort Plus',
          bc: '8.7',
          powerMax: '8',
          powerMin: '-15',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Alcon',
          name: 'DAILIES AquaComfort Plus Multifocal',
          bc: '8.7',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '3',
            value1: 'Lo',
            value2: 'Med',
            value3: 'Hi',
          },
          replace: '1 day',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Alcon',
          name: 'DAILIES AquaComfort Plus Toric',
          bc: '8.8',
          powerMax: '4',
          powerMin: '-8',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl:
            [
             {pMin: '-6.00', pMax: '4.00', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '-8.00', pMax: '-6.50', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
           ],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Alcon',
          name: 'DAILIES COLORS',
          bc: '8.6',
          powerMax: '0',
          powerMin: '-8',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Alcon',
          name: 'Dailies Total 1',
          bc: '8.5',
          powerMax: '6',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Alcon',
          name: 'Dailies Total 1 for Astigmatism',
          bc: '8.6',
          powerMax: '4',
          powerMin: '-8',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
             {pMin: '0.25', pMax: '4', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '0.25', pMax: '4', cMin: '-2.25', cMax: '-2.25', degrees: [10, 20, 160, 170, 180]},
             {pMin: '-6', pMax: '0', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
             {pMin: '-6', pMax: '0', cMin: '-2.25', cMax: '-2.25', degrees: [10, 20, 160, 170, 180]},
             {pMin: '-8.00', pMax: '-6.50', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '-8.00', pMax: '-6.50', cMin: '-2.25', cMax: '-2.25', degrees: [10, 20, 160, 170, 180]},
           ],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Alcon',
          name: 'Dailies Total 1 Multifocal',
          bc: '8.5',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '3',
            value1: 'Lo',
            value2: 'Med',
            value3: 'Hi',
          },
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Alcon',
          name: 'Precision1',
          bc: '8.3',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Alcon',
          name: 'Precision1 for Astigmatism',
          bc: '8.5',
          powerMax: '4',
          powerMin: '-8',
          halfStepsMinus: '-6',
          halfStepsPlus: '0',
          cyl: [
             {pMin: '-6', pMax: '0', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
             {pMin: '-6', pMax: '0', cMin: '-2.25', cMax: '-2.25', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '0.25', pMax: '4', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '0.25', pMax: '4', cMin: '-2.25', cMax: '-2.25', degrees: [10, 20, 160, 170, 180]},
             {pMin: '-8.00', pMax: '-6.50', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '-8.00', pMax: '-6.50', cMin: '-2.25', cMax: '-2.25', degrees: [10, 20, 160, 170, 180]},
           ],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Alcon',
          name: 'Total30',
          bc: '8.4',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-8',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Alcon',
          name: 'Total30 for Astigmatism',
          bc: '8.6',
          powerMax: '8',
          powerMin: '-10',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [
            {pMin: '-10.00', pMax: '8.00', cMin: '-2.25', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
          ],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Alcon',
          name: 'Total30 Multifocal',
          bc: '8.4',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '3',
            value1: 'Lo',
            value2: 'Med',
            value3: 'Hi',
          },
          replace: '4 weeks',
          altName: [],
        },

//Bausch + Lomb lenses====================================
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'Biotrue ONEday',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-12',
          halfStepsMinus: '-6.5',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Bausch + Lomb',
          name: 'Biotrue ONEday for Astigmatism',
          bc: '8.4',
          powerMax: '4',
          powerMin: '-9',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-6.00', pMax: '0', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
             {pMin: '-6.00', pMax: '0', cMin: '-2.25', cMax: '-2.25', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '-9.00', pMax: '-6.50', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 60, 70, 80, 90, 100, 110, 120, 160, 170, 180]},
             {pMin: '-9.00', pMax: '-6.50', cMin: -2.25, cMax: -2.25, degrees: [10, 20, 90, 160, 170, 180]},
             {pMin: '0.25', pMax: '4.00', cMin: -1.75, cMax: -0.75, degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '0.25', pMax: '4.00', cMin: -2.25, cMax: -2.25, degrees: [10, 20, 80, 90, 100, 160, 170, 180]},
           ],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Bausch + Lomb',
          name: 'Biotrue ONEday for Astigmatism (-2.75 cyl)',
          bc: '8.4',
          powerMax: '0',
          powerMin: '-6',
          halfStepsMinus: '0',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-6.00', pMax: '0', cMin: '-2.75', cMax: '-2.75', degrees: [10, 20, 90, 160, 170, 180]},
           ],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Bausch + Lomb',
          name: 'Biotrue ONEday for Presbyopia',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-9',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '2',
            value1: 'Lo',
            value2: 'Hi',
          },
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'INFUSE One-Day',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Bausch + Lomb',
          name: 'INFUSE One-Day for Astigmatism',
          bc: '8.6',
          powerMax: '4',
          powerMin: '-8',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
            {pMin: '-6.00', pMax: '0', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
            {pMin: '-6.00', pMax: '0', cMin: '-2.75', cMax: '-2.25', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
            {pMin: '-8.00', pMax: '-6.50', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
            {pMin: '0.25', pMax: '4.00', cMin: -1.75, cMax: -0.75, degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
          ],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'INFUSE Multifocal',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '2',
            value1: 'Lo',
            value2: 'Hi',
          },
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'PureVision (BC 8.3)',
          bc: '8.3',
          powerMax: '-0.25',
          powerMin: '-6',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'PureVision (BC 8.6)',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Bausch + Lomb',
          name: 'PureVision Multifocal',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '2',
            value1: 'Lo',
            value2: 'Hi',
          },
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'PureVision2 (discontinued)',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: ['Walmart Equate Monthly',],
        },
        {
          type: 'toric',
          brand: 'Bausch + Lomb',
          name: 'PureVision2 for Astigmatism (discontinued)',
          bc: '8.9',
          powerMax: '6',
          powerMin: '-9',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-9.00', pMax: '6', cMin: '-2.25', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
           ],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Bausch + Lomb',
          name: 'PureVision2 Multi-Focal (discontinued)',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '2',
            value1: 'Lo',
            value2: 'Hi',
          },
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'Soflens 38 (BC 8.4)',
          bc: '8.4',
          powerMax: '4',
          powerMin: '-9',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'Soflens 38 (BC 8.7)',
          bc: '8.7',
          powerMax: '4',
          powerMin: '-9',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'Soflens 38 (BC 9.0)',
          bc: '9.0',
          powerMax: '4',
          powerMin: '-9',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'Optima FW (BC 8.4)',
          bc: '8.4',
          powerMax: '4',
          powerMin: '-9',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'Optima FW (BC 8.7)',
          bc: '8.7',
          powerMax: '4',
          powerMin: '-9',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'Optima FW (BC 9.0)',
          bc: '9.0',
          powerMax: '4',
          powerMin: '-9',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'Optima38/SP (BC 8.4)',
          bc: '8.4',
          powerMax: '5',
          powerMin: '-12',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '1 year',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'Optima38/SP (BC 8.7)',
          bc: '8.7',
          powerMax: '5',
          powerMin: '-12',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '1 year',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'Optima38/SP (BC 9.0)',
          bc: '9.0',
          powerMax: '5',
          powerMin: '-9',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '1 year',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'Soflens Daily Disposable',
          bc: '8.6',
          powerMax: '6.5',
          powerMin: '-9',
          halfStepsMinus: '-6.5',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Bausch + Lomb',
          name: 'Soflens Multifocal (BC 8.5) (discontinued)',
          bc: '8.5',
          powerMax: '6',
          powerMin: '-7',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: {
            addStep: '2',
            value1: 'Lo',
            value2: 'Hi',
          },
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Bausch + Lomb',
          name: 'Soflens Multifocal (BC 8.8) (discontinued)',
          bc: '8.8',
          powerMax: '6',
          powerMin: '-7',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: {
            addStep: '2',
            value1: 'Lo',
            value2: 'Hi',
          },
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Bausch + Lomb',
          name: 'Soflens Toric',
          bc: '8.5',
          powerMax: '6',
          powerMin: '-9',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-9.00', pMax: '0', cMin: '-2.75', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
             {pMin: '0.25', pMax: '6', cMin: '-2.25', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
           ],
          add: '',
          replace: '2 weeks',
          altName: ['Soflens 66 Toric'],
        },
        {
          type: 'spherical',
          brand: 'Bausch + Lomb',
          name: 'Ultra',
          bc: '8.5',
          powerMax: '6',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Bausch + Lomb',
          name: 'Ultra for Astigmatism',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-9',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-9.00', pMax: '6', cMin: '-2.75', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
           ],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toricMultifocal',
          brand: 'Bausch + Lomb',
          name: 'Ultra Multifocal for Astigmatism',
          bc: '8.6',
          powerMax: '4',
          powerMin: '-6',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-6.00', pMax: '4', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
             {pMin: '-6.00', pMax: '4', cMin: '-2.75', cMax: '-2.25', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
           ],
          add: {
            addStep: '2',
            value1: 'Lo',
            value2: 'Hi',
          },
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Bausch + Lomb',
          name: 'Ultra for presbyopia',
          bc: '8.5',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '2',
            value1: 'Lo',
            value2: 'Hi',
          },
          replace: '4 weeks',
          altName: [],
        },

//CooperVision lenses====================================

        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'Avaira Vitality',
          bc: '8.4',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [
            "Aquaclear 110",
            "Sofmed Breathables Advanced",
            ],
        },
        {
          type: 'toric',
          brand: 'CooperVision',
          name: 'Avaira Vitality Toric',
          bc: '8.5',
          powerMax: '8',
          powerMin: '-10',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-10.00', pMax: '8', cMin: '-2.25', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
           ],
          add: '',
          replace: '2 weeks',
          altName: [
            "Aquaclear 110 Toric",
            "Sofmed Breathables Advanced Toric",
          ],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'Biofinity',
          bc: '8.6',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [
            "Aquaclear",
            "SIH-48",
            "Sofmed Breathables XW",
            "Aquatech Plus Premium",
          ],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'Biofinity XR',
          bc: '8.6',
          powerMax: '15',
          powerMin: '-20',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [
            "Aquaclear",
            "SIH-48",
            "Sofmed Breathables XW",
            "Aquatech Plus Premium",
          ],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'Biofinity Energys',
          bc: '8.6',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: {
            addStep: '1',
            value1: 'For digital device users',
          },
          replace: '4 weeks',
          altName: [
          ],
        },
        {
          type: 'multifocal',
          brand: 'CooperVision',
          name: 'Biofinity Multifocal',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '4',
            value1: '+1.00 (D)',
            value2: '+1.50 (D)',
            value3: '+2.00 (select N or D)',
            value4: '+2.50 (select N or D)',
          },
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'CooperVision',
          name: 'Biofinity Toric',
          bc: '8.7',
          powerMax: '8',
          powerMin: '-10',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [
             {pMin: '-10', pMax: '8', cMin: '-2.25', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
           ],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'CooperVision',
          name: 'Biofinity Toric XR',
          bc: '8.7',
          powerMax: '20',
          powerMin: '-20',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [
             {pMin: '-20', pMax: '20', cMin: '-5.75', cMax: '-2.75', degrees: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]},
             {pMin: '8.5', pMax: '20', cMin: '-2.25', cMax: '-0.75', degrees: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]},
             {pMin: '-20', pMax: '-10.5', cMin: '-2.25', cMax: '-0.75', degrees: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]},
           ],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toricMultifocal',
          brand: 'CooperVision',
          name: 'Biofinity Toric Multifocal',
          bc: '8.7',
          powerMax: '10',
          powerMin: '-10',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [
             {pMin: '-10', pMax: '10', cMin: '-5.75', cMax: '-0.75', degrees: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]},
           ],
          add: {
            addStep: '4',
            value1: '+1.00 (D)',
            value2: '+1.50 (D)',
            value3: '+2.00 (select N or D)',
            value4: '+2.50 (select N or D)',
          },
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'Biomedics 55 Premier (BC 8.6)',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '-6',
          halfStepsPlus: '5',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [
            "Aqualens 55 Aspheric",
            "Aqualite 55 Pro Aspheric",
            "Aqualite 55 Pro",
            "Aquatech 2",
            "Biomedics 55 Evolution",
            "Biomedics 55 Premier",
            "Clearsoft 2",
            "Clinasoft 55 Pro",
            "(Walmart) Equate 2week Aspheric",
            "Flexsoft II Pro",
            "Flextique 2",
            "Hydroflex 2",
            "Hydrovue 55 Aspheric",
            "Mediflex II 55 Aspheric",
            "Medisoft 2",
            "Neoflex 55 Aspheric",
            "Neotech 55 Eclipse",
            "Ocupure 55 Aspheric",
            "Ocusite Pro",
            "Omniflex 2",
            "Optica 55 Aspheric",
            "Optiflex 2",
            "Optiform 55 Aspheric",
            "Optiview 55 Aspheric",
            "Perspecta Aspheric",
            "Polyform II",
            "Polysoft 55 Aspheric",
            "Procon II",
            "Proflex 2",
            "Prosite 55 Aspheric",
            "Sofmed 55",
            "Softech 2",
            "Softique II",
            "Softview 55 Pro",
            "Softvue II",
            "Spectera 2",
            "Target Aspheric",
            "Ultraflex II",
            "Versaflex 55 Aspheric",
          ],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'Biomedics 55 Premier (BC 8.8)',
          bc: '8.8',
          powerMax: '6',
          powerMin: '0',
          halfStepsMinus: '-6',
          halfStepsPlus: '5',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [
            "Aqualens 55 Aspheric",
            "Aqualite 55 Pro Aspheric",
            "Aqualite 55 Pro",
            "Aquatech 2",
            "Biomedics 55 Evolution",
            "Biomedics 55 Premier",
            "Clearsoft 2",
            "Clinasoft 55 Pro",
            "(Walmart) Equate 2week Aspheric",
            "Flexsoft II Pro",
            "Flextique 2",
            "Hydroflex 2",
            "Hydrovue 55 Aspheric",
            "Mediflex II 55 Aspheric",
            "Medisoft 2",
            "Neoflex 55 Aspheric",
            "Neotech 55 Eclipse",
            "Ocupure 55 Aspheric",
            "Ocusite Pro",
            "Omniflex 2",
            "Optica 55 Aspheric",
            "Optiflex 2",
            "Optiform 55 Aspheric",
            "Optiview 55 Aspheric",
            "Perspecta Aspheric",
            "Polyform II",
            "Polysoft 55 Aspheric",
            "Procon II",
            "Proflex 2",
            "Prosite 55 Aspheric",
            "Sofmed 55",
            "Softech 2",
            "Softique II",
            "Softview 55 Pro",
            "Softvue II",
            "Spectera 2",
            "Target Aspheric",
            "Ultraflex II",
            "Versaflex 55 Aspheric",
          ],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'Biomedics 55 Premier (BC 8.9)',
          bc: '8.9',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '-6',
          halfStepsPlus: '5',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [
            "Aqualens 55 Aspheric",
            "Aqualite 55 Pro Aspheric",
            "Aqualite 55 Pro",
            "Aquatech 2",
            "Biomedics 55 Evolution",
            "Biomedics 55 Premier",
            "Clearsoft 2",
            "Clinasoft 55 Pro",
            "(Walmart) Equate 2week Aspheric",
            "Flexsoft II Pro",
            "Flextique 2",
            "Hydroflex 2",
            "Hydrovue 55 Aspheric",
            "Mediflex II 55 Aspheric",
            "Medisoft 2",
            "Neoflex 55 Aspheric",
            "Neotech 55 Eclipse",
            "Ocupure 55 Aspheric",
            "Ocusite Pro",
            "Omniflex 2",
            "Optica 55 Aspheric",
            "Optiflex 2",
            "Optiform 55 Aspheric",
            "Optiview 55 Aspheric",
            "Perspecta Aspheric",
            "Polyform II",
            "Polysoft 55 Aspheric",
            "Procon II",
            "Proflex 2",
            "Prosite 55 Aspheric",
            "Sofmed 55",
            "Softech 2",
            "Softique II",
            "Softview 55 Pro",
            "Softvue II",
            "Spectera 2",
            "Target Aspheric",
            "Ultraflex II",
            "Versaflex 55 Aspheric",
          ],
        },
        {
          type: 'toric',
          brand: 'CooperVision',
          name: 'Biomedics Toric',
          bc: '8.7',
          powerMax: '6',
          powerMin: '-9',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-9', pMax: '6', cMin: '-2.25', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
           ],
          add: '',
          replace: '2 weeks',
          altName: [
            "Walmart Equate 2 week for Astigmatism",
            "Ultraflex Toric"
            ],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'Clariti 1 Day',
          bc: '8.6',
          powerMax: '8',
          powerMin: '-10',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [
            "Fresh Day"
          ],
        },
        {
          type: 'multifocal',
          brand: 'CooperVision',
          name: 'Clariti 1 Day Multifocal',
          bc: '8.6',
          powerMax: '5',
          powerMin: '-6',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '2',
            value1: 'Low',
            value2: 'High',
          },
          replace: '1 day',
          altName: [
            "Fresh Day Multifocal"
          ],
        },
        {
          type: 'toric',
          brand: 'CooperVision',
          name: 'Clariti 1 Day Toric',
          bc: '8.6',
          powerMax: '4',
          powerMin: '-9',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
           {pMin: '-6.00', pMax: '0', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
           {pMin: '-6.00', pMax: '0', cMin: '-2.25', cMax: '-2.25', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
           {pMin: '-9.00', pMax: '-6.50', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 60, 70, 80, 90, 100, 110, 120, 160, 170, 180]},
           {pMin: '-9.00', pMax: '-6.50', cMin: -2.25, cMax: -2.25, degrees: [10, 20, 90, 160, 170, 180]},
           {pMin: '0.25', pMax: '4.00', cMin: -1.75, cMax: -0.75, degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
         ],
          add: '',
          replace: '1 day',
          altName: [
            "Fresh Day for Astigmatism"
          ],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'MiSight 1 Day',
          bc: '8.7',
          powerMax: '-0.25',
          powerMin: '-7.00',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'MyDay',
          bc: '8.4',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '5',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [
            "1-Day Premium",
            "Premium Daily Disposables",
          ],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'MyDay Energys',
          bc: '8.4',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '5',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'CooperVision',
          name: 'MyDay multifocal',
          bc: '8.4',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-10',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '3',
            value1: 'Low',
            value2: 'Mid',
            value3: 'High',
          },
          replace: '1 day',
          altName: [
            "1-Day Premium",
            "Premium Daily Disposables",
          ],
        },
        {
          type: 'toric',
          brand: 'CooperVision',
          name: 'MyDay Toric',
          bc: '8.6',
          powerMax: '8',
          powerMin: '-10',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [
           {pMin: '-10.00', pMax: '8.00', cMin: '-2.25', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
         ],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'Proclear 1 Day',
          bc: '8.7',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [
            "Clear Choice Premium 1 Day",
          ],
        },
        {
          type: 'multifocal',
          brand: 'CooperVision',
          name: 'Proclear 1 Day Multifocal',
          bc: '8.7',
          powerMax: '6',
          powerMin: '-10',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: {
            addStep: '1',
            value1: 'Yes (single add power)',
          },
          replace: '1 day',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'CooperVision',
          name: 'Proclear Multifocal',
          bc: '8.7',
          powerMax: '6',
          powerMin: '-8',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '4',
            value1: '+1.00 (D)',
            value2: '+1.50 (D)',
            value3: '+2.00 (select N or D)',
            value4: '+2.50 (select N or D)',
          },
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'CooperVision',
          name: 'Proclear Multifocal XR (BC 8.7)',
          bc: '8.7',
          powerMax: '20',
          powerMin: '-20',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '4',
            value1: '+1.00 (D)',
            value2: '+1.50 (D)',
            value3: '+2.00 (select N or D)',
            value4: '+2.50 up to +4.00 in 0.5 steps (select N or D)',
          },
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'CooperVision',
          name: 'Proclear Multifocal XR (BC 8.4)',
          bc: '8.4',
          powerMax: '20',
          powerMin: '-20',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '4',
            value1: '+1.00 (D)',
            value2: '+1.50 (D)',
            value3: '+2.00 (select N or D)',
            value4: '+2.50 up to +4.00 in 0.5 steps (select N or D)',
          },
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toricMultifocal',
          brand: 'CooperVision',
          name: 'Proclear Multifocal Toric (BC 8.4)',
          bc: '8.4',
          powerMax: '20',
          powerMin: '-20',
          halfStepsMinus: '-6.50',
          halfStepsPlus: '6.50',
          cyl: [
             {pMin: '-20', pMax: '20', cMin: '-5.75', cMax: '-0.75', degrees: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]},
           ],
          add: {
            addStep: '4',
            value1: '+1.00 (D)',
            value2: '+1.50 (D)',
            value3: '+2.00 (select N or D)',
            value4: '+2.50 up to +4.00 in 0.5 steps (select N or D)',
          },
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toricMultifocal',
          brand: 'CooperVision',
          name: 'Proclear Multifocal Toric (BC 8.8)',
          bc: '8.8',
          powerMax: '20',
          powerMin: '-20',
          halfStepsMinus: '-6.50',
          halfStepsPlus: '6.50',
          cyl: [
             {pMin: '-20', pMax: '20', cMin: '-5.75', cMax: '-0.75', degrees: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]},
           ],
          add: {
            addStep: '4',
            value1: '+1.00 (D)',
            value2: '+1.50 (D)',
            value3: '+2.00 (select N or D)',
            value4: '+2.50 up to +4.00 in 0.5 steps (select N or D)',
          },
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'Proclear Sphere (BC 8.2)',
          bc: '8.2',
          powerMax: '-0.25',
          powerMin: '-10',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'CooperVision',
          name: 'Proclear Sphere (BC 8.6)',
          bc: '8.6',
          powerMax: '20',
          powerMin: '-20',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'CooperVision',
          name: 'Proclear Toric (BC 8.4)',
          bc: '8.4',
          powerMax: '6',
          powerMin: '-8',
          halfStepsMinus: '-6.50',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-8.00', pMax: '6', cMin: '-2.25', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
            ],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'CooperVision',
          name: 'Proclear Toric (BC 8.8)',
          bc: '8.8',
          powerMax: '6',
          powerMin: '-8',
          halfStepsMinus: '-6.50',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-8.00', pMax: '6', cMin: '-2.25', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
            ],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'CooperVision',
          name: 'Proclear Toric XR (BC 8.4)',
          bc: '8.4',
          powerMax: '10',
          powerMin: '-10',
          halfStepsMinus: '-6.50',
          halfStepsPlus: '',
          cyl: [
             {pMin: '6.25', pMax: '10', cMin: '-2.25', cMax: '-0.75', degrees: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]},
             {pMin: '-8.50', pMax: '-10', cMin: '-2.25', cMax: '-0.75', degrees: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]},
             {pMin: '-10.00', pMax: '10', cMin: '-5.75', cMax: '-2.75', degrees: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]},
            ],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'CooperVision',
          name: 'Proclear Toric XR (BC 8.8)',
          bc: '8.8',
          powerMax: '10',
          powerMin: '-10',
          halfStepsMinus: '-6.50',
          halfStepsPlus: '',
          cyl: [
             {pMin: '6.25', pMax: '10', cMin: '-2.25', cMax: '-0.75', degrees: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]},
             {pMin: '-8.50', pMax: '-10', cMin: '-2.25', cMax: '-0.75', degrees: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]},
             {pMin: '-10.00', pMax: '10', cMin: '-5.75', cMax: '-2.75', degrees: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]},
            ],
          add: '',
          replace: '4 weeks',
          altName: [],
        },



//J&J Acuvue lenses====================================
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: '1-Day Acuvue Define (Accent, Shine, or Vivid)',
          bc: '8.5',
          powerMax: '1',
          powerMin: '-9',
          halfStepsMinus: '-6',
          halfStepsPlus: '-0.5',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: '1-Day Acuvue Moist (BC 8.5)',
          bc: '8.5',
          powerMax: '6',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: '1-Day Acuvue Moist (BC 9.0)',
          bc: '9.0',
          powerMax: '6',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Acuvue',
          name: '1-Day Acuvue Moist for Astigmatism',
          bc: '8.5',
          powerMax: '4',
          powerMin: '-9',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-6', pMax: '0', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
             {pMin: '-6', pMax: '0', cMin: '-2.25', cMax: '-2.25', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '-9', pMax: '-6.5', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '0.25', pMax: '4', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
            ],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Acuvue',
          name: '1-Day Acuvue Moist Multifocal',
          bc: '8.4',
          powerMax: '6',
          powerMin: '-9',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '3',
            value1: 'Low',
            value2: 'Medium',
            value3: 'High',
          },
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: 'Acuvue 2 (BC 8.3)',
          bc: '8.3',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: 'Acuvue 2 (BC 8.7)',
          bc: '8.7',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: 'Acuvue Oasys (BC 8.4)',
          bc: '8.4',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: 'Acuvue Oasys (BC 8.8)',
          bc: '8.8',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Acuvue',
          name: 'Acuvue Oasys Astigmatism',
          bc: '8.6',
          powerMax: '6',
          powerMin: '-9',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
             {pMin: '0.25', pMax: '6', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '-6', pMax: '0', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
             {pMin: '-6', pMax: '0', cMin: '-2.75', cMax: '-2.25', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '-9', pMax: '-6.50', cMin: '-1.25', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
             {pMin: '-9', pMax: '-6.50', cMin: '-2.75', cMax: '-1.75', degrees: [10, 20, 160, 170, 180]},
            ],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Acuvue',
          name: 'Acuvue Oasys Multifocal',
          bc: '8.4',
          powerMax: '6',
          powerMin: '-9',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '3',
            value1: 'Low',
            value2: 'Medium',
            value3: 'High',
          },
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: 'Acuvue Oasys 1 Day (BC 8.5)',
          bc: '8.5',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: 'Acuvue Oasys 1 Day (BC 9.0)',
          bc: '9.0',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Acuvue',
          name: 'Acuvue Oasys 1-Day for Astigmatism',
          bc: '8.5',
          powerMax: '4',
          powerMin: '-9',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-6', pMax: '0', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
             {pMin: '-6', pMax: '0', cMin: '-2.25', cMax: '-2.25', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '-9', pMax: '-6.5', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '0.25', pMax: '4', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
            ],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: 'Acuvue Oasys Max 1-Day',
          bc: '8.5',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '1 day',
          altName: [],
        },
        {
          type: 'multifocal',
          brand: 'Acuvue',
          name: 'Acuvue Oasys Max 1-Day Multifocal',
          bc: '8.4',
          powerMax: '6',
          powerMin: '-9',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: {
            addStep: '3',
            value1: 'Low',
            value2: 'Medium',
            value3: 'High',
          },
          replace: '1 day',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: 'Acuvue Oasys with Transitions (discontinued, BC 8.4)',
          bc: '8.4',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: 'Acuvue Oasys with Transitions (discontinued, BC 8.8)',
          bc: '8.8',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '2 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: 'Acuvue Vita (BC 8.4)',
          bc: '8.4',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'spherical',
          brand: 'Acuvue',
          name: 'Acuvue Vita (BC 8.8)',
          bc: '8.8',
          powerMax: '8',
          powerMin: '-12',
          halfStepsMinus: '-6',
          halfStepsPlus: '6',
          cyl: [],
          add: '',
          replace: '4 weeks',
          altName: [],
        },
        {
          type: 'toric',
          brand: 'Acuvue',
          name: 'Acuvue Vita for Astigmatism',
          bc: '8.6',
          powerMax: '4',
          powerMin: '-9',
          halfStepsMinus: '-6',
          halfStepsPlus: '',
          cyl: [
             {pMin: '-6', pMax: '0', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180]},
             {pMin: '-6', pMax: '0', cMin: '-2.25', cMax: '-2.25', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '-9', pMax: '-6.5', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
             {pMin: '0.25', pMax: '4', cMin: '-1.75', cMax: '-0.75', degrees: [10, 20, 70, 80, 90, 100, 110, 160, 170, 180]},
            ],
          add: '',
          replace: '4 weeks',
          altName: [],
        },


//blank template====================================
        {
          type: '',
          brand: '',
          name: '',
          bc: '',
          powerMax: '',
          powerMin: '',
          halfStepsMinus: '',
          halfStepsPlus: '',
          cyl: [],
          add: '',
          replace: '',
          altName: [],
        },
      ];
