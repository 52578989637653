import ContactsToricMultifocalR from './ContactsToricMultifocalR'
import ContactsMultifocalR from './ContactsMultifocalR'
import ContactsToricR from './ContactsToricR'
import ContactsSphericalR from './ContactsSphericalR'


function Contacts (props) {

let minusCheck = (props.cyl*1 === "" || props.cyl === "sphere" || props.cyl === "sph" || props.cyl*1 === 0)
  ? 0
  : (props.cyl*1 < 0)
    ? true
    : false;

let powerCheck = ((props.power === "plano" || props.power === "pl") && (  props.cyl === "sphere" || props.cyl === "sph"))
  ? 0
  : ((props.power === "plano" || props.power === "pl") && (props.cyl > 0))
    ? props.cyl*1
    : ((props.power === "plano" || props.power === "pl") && (props.cyl < 0))
      ? 0
      : (minusCheck === false)
        ? props.power*1 + props.cyl*1
        : props.power*1;

let cylCheck = (props.cyl === "sphere" || props.cyl === "sph")
  ? 0
  : (props.cyl*1 > 0)
    ? props.cyl*-1
    : props.cyl*1;

let axisCheck = (props.axis === '')
  ? ''
  : (minusCheck === false)
    ? (props.axis*1 > 0 && props.axis <= 90)
      ? (props.axis*1 + 90)
      : (props.axis*1 - 90)
    : props.axis*1;

//_____________________________________________________________________________

let vertexPower = (powerCheck/(1 - (props.vertexDistance*0.001*powerCheck)))

let vertexCyl = (cylCheck/(1 - (props.vertexDistance*0.001*cylCheck)))

let sphEqPower = vertexPower + 0.5*vertexCyl;


//_____________________________________________________________________________

let halfStepsPlus = (props.contactPicked.halfStepsPlus === '')
  ? props.contactPicked.powerMax
  : props.contactPicked.halfStepsPlus

let halfStepsMinus = (props.contactPicked.halfStepsMinus === '')
  ? props.contactPicked.powerMin
  : props.contactPicked.halfStepsMinus

let roundPower = (vertexPower < halfStepsMinus || vertexPower > halfStepsPlus)
  ? (props.contactPicked.type === 'toric' && props.monovision === true)
    ? (Math.round(vertexPower/0.5)*0.5 + props.addPower*1 -0.25)
    : (props.contactPicked.type === 'spherical' && props.monovision === true)
      ? (Math.round(sphEqPower/0.5)*0.5 + props.addPower*1 -0.25)
      : (props.contactPicked.type === 'spherical' || props.contactPicked.type === 'multifocal')
        ? Math.round(sphEqPower/0.5)*0.5
        : Math.round(vertexPower/0.5)*0.5
  : (props.contactPicked.type === 'toric' && props.monovision === true)
    ? (Math.round(vertexPower/0.25)*0.25 + props.addPower*1 -0.25)
    : (props.contactPicked.type === 'spherical' && props.monovision === true)
      ? (Math.round(sphEqPower/0.25)*0.25 + props.addPower*1 -0.25)
      : (props.contactPicked.type === 'spherical' || props.contactPicked.type === 'multifocal')
        ? Math.round(sphEqPower/0.25)*0.25
        : Math.round(vertexPower/0.25)*0.25


let roundCyl = (minusCheck === 0)
  ? 0
  : (Math.abs(cylCheck - vertexCyl) < 0.25)
    ? ((Math.round(cylCheck/0.25))%2 === 0 )
      ? (Math.round((cylCheck/0.25) + 1))*0.25
      : (Math.round(cylCheck/0.25))*0.25
    : ((Math.round(vertexCyl/0.25))%2 === 0 )
      ? (Math.round((vertexCyl/0.25) + 1))*0.25
      : (Math.round(vertexCyl/0.25))*0.25


// if vertexCyl is within 0.25 of actual cylinder, then use actual cyilnder

//____________________________________________________________________________


let pBetween = props.contactPicked === ''
  ? ''
  : props.contactPicked.cyl.filter(c =>
      roundPower >= c.pMin*1 &&
      roundPower <= c.pMax*1
  )

let pHigh = props.contactPicked === ''
    ? ''
    : props.contactPicked.cyl.filter(c =>
      roundPower >= c.pMin*1 &&
      roundPower >= c.pMax*1
    ).length > 0
      ? props.contactPicked.powerMax*1
      : '';

let pLow = props.contactPicked === ''
    ? ''
    : props.contactPicked.cyl.filter(c =>
      roundPower <= c.pMin*1 &&
      roundPower <= c.pMax*1
    ).length > 0
      ? props.contactPicked.powerMin*1
      : '';


let pPicked = (props.contactPicked === '')
  ? ''
  : (props.contactPicked.type === 'spherical' || props.contactPicked.type === 'multifocal')
    ? (roundPower === 0)
      ? 0
      : (props.contactPicked.powerMax*1 < roundPower)
        ? (props.contactPicked.powerMax*1)
        : (props.contactPicked.powerMin*1 > roundPower)
          ? (props.contactPicked.powerMin*1)
          : roundPower
    : pBetween.length >=1
      ? roundPower
      : pBetween.length === 0 && pHigh === ''
        ? pLow
        : pBetween.length === 0 && pLow === ''
          ? pHigh
          : ''

let cBetween = props.contactPicked === ''
  ? ''
  : props.contactPicked.cyl.filter(c =>
      pPicked >= c.pMin*1 &&
      pPicked <= c.pMax*1 &&
      roundCyl <= c.cMax*1 &&
      roundCyl >= c.cMin*1
  )

let cLow = props.contactPicked === ''
    ? ''
    : props.contactPicked.cyl.filter(c =>
        pPicked*1 >= c.pMin*1 &&
        pPicked*1 <= c.pMax*1 &&
        roundCyl*1 <= c.cMin*1
    )

let cMinArray= cLow === ''
  ? ''
  : cLow.map(c =>
      c.cMin*1
  )

let cPicked = props.contactPicked === ''
  ? ''
  : cBetween.length > 0
    ? roundCyl
    : cBetween.length === 0 && cLow.length > 0
      ? cMinArray === ''
        ? ''
        : Math.min(...cMinArray)
      : ''

let aFind = props.contactPicked === ''
  ? ''
  : props.contactPicked.cyl.filter(c =>
      c.pMin*1 <= pPicked &&
      c.pMax*1 >= pPicked &&
      c.cMax*1 >= cPicked &&
      c.cMin*1 <= cPicked
  )

let aMatch = props.contactPicked === ''
  ? ''
  : cPicked === 0 || cPicked === ''
    ? ''
    : (axisCheck >= 1 && axisCheck <= 180)
      ? 'x' + aFind[0].degrees.reduce((prev, curr) =>
        Math.abs(curr - axisCheck) < Math.abs(prev - axisCheck)
          ? curr
          : (prev-axisCheck >= prev/2)
            ? aFind[0].degrees.reduce((prev, curr) =>
              Math.abs(curr - 180) < Math.abs(prev - 180)
                ? curr
                : prev
              )
            : prev
        )
      : 'check axis'

//____________________________________________________________________________

let powerWarning = (props.contactPicked.type === 'spherical' || props.contactPicked.type === 'multifocal')
  ? (props.contactPicked.powerMax*1 < roundPower)
    ? `WARNING: power needed is +${roundPower.toFixed(2)}`
    : (props.contactPicked.powerMin*1 > roundPower)
      ? `WARNING: power needed is ${roundPower.toFixed(2)}`
      : ''
  : pBetween.length === 1
    ? ""
    : pBetween.length === 0 && pHigh > 0
      ? `WARNING: power needed is +${roundPower.toFixed(2)}`
      : pBetween.length === 0 && pLow < 0
        ? `WARNING: power needed is ${roundPower.toFixed(2)}`
        : ''


let cylWarning = cBetween.length === 1
  ? ""
  : cBetween.length === 0 && cLow.length > 0
      ? `WARNING: cylinder needed is ${roundCyl.toFixed(2)}`
      : ""


//____________________________________________________________________________

let add4Step = (props.contactPicked === "" || props.contactPicked.add === "")
  ? 'no add needed'
  : (props.addPower*1 <= 1.25)
    ? (props.contactPicked.add.value1)
    : (props.addPower*1 >= 1.50 && props.addPower*1 <= 1.75)
      ? (props.contactPicked.add.value2)
      : (props.addPower*1 >= 2.00 && props.addPower*1 <= 2.25)
        ? (props.contactPicked.add.value3)
        : (props.addPower*1 >= 2.50)
          ? (props.contactPicked.add.value4)
          : '';

let add3Step = (props.contactPicked === "" || props.contactPicked.add === "")
  ? 'no add needed'
  : (props.addPower*1 <= 1.50)
    ? (props.contactPicked.add.value1)
    : (props.addPower*1 >= 1.75 && props.addPower*1 <= 2.00)
      ? (props.contactPicked.add.value2)
      : (props.addPower*1 >= 2.25)
        ? (props.contactPicked.add.value3)
        : '';

let add2Step = (props.contactPicked === "" || props.contactPicked.add === "")
  ? 'no add needed'
  : (props.addPower*1 <= 1.75)
    ? (props.contactPicked.add.value1)
    : (props.addPower*1 >= 2.00)
      ? (props.contactPicked.add.value2)
      : '';

let add1Step = (props.contactPicked === "" || props.contactPicked.add === "")
  ? 'no add needed'
  : (props.contactPicked.add.value1)

//____________________________________________________________________________


let finalPower = (pPicked === 0 || pPicked === -0)
  ? 'Plano'
  : pPicked*1 > 0
    ? '+' + pPicked.toFixed(2)
    : pPicked*1 < 0
      ? pPicked.toFixed(2)
      : 'Check power'

let finalCyl = (cPicked === 0 || cPicked === -0)
  ? 'sphere'
  : cPicked*1 < 0
    ? cPicked.toFixed(2)
    : 'Check cylinder';

let finalAdd = (props.contactPicked === "" || props.contactPicked.add === "")
  ? 'no add needed'
  : (props.contactPicked.add.addStep*1 === 4)
    ? add4Step
    : (props.contactPicked.add.addStep*1 === 3)
      ? add3Step
      : (props.contactPicked.add.addStep*1 === 2)
        ? add2Step
        : (props.contactPicked.add.addStep*1 === 1)
          ? add1Step
          : 'Check your add power'


  //__________________________________________________________________________



return (

  <div>
    {
      (props.contactPicked.type === 'toricMultifocal')
        ? <ContactsToricMultifocalR
          power={finalPower}
          cyl={finalCyl}
          axis={aMatch}
          addPower={props.addPower}
          add={finalAdd}
          powerWarning={powerWarning}
          cylWarning={cylWarning}
          contactPicked={props.contactPicked}
          />
        : (props.contactPicked.type === 'multifocal')
          ? <ContactsMultifocalR
            power={finalPower}
            addPower={props.addPower}
            add={finalAdd}
            powerWarning={powerWarning}
            contactPicked={props.contactPicked}
            />
          : (props.contactPicked.type === 'toric')
            ? <ContactsToricR
              power={finalPower}
              monovision = {props.monovision}
              cyl={finalCyl}
              axis={aMatch}
              addPower={props.addPower}
              powerWarning={powerWarning}
              cylWarning={cylWarning}
              contactPicked={props.contactPicked}
              />
            : (props.contactPicked.type === 'spherical')
              ? <ContactsSphericalR
                power={finalPower}
                monovision = {props.monovision}
                addPower={props.addPower}
                powerWarning={powerWarning}
                contactPicked={props.contactPicked}
                />
              : ''
    }
  </div>

)


}

export default Contacts
