function FormInput(props){


  let inputStyle={
    border: 'none',
    padding: '3px 3px 3px 40px',
    margin: '2px',
    fontSize: '18px',
    width: '100px',
    height: '40px',
    borderRadius: '5px',
    background: 'rgb(242, 243, 244)',
  }

  let pStyle={
    width: '147px',
    margin: '0px',
    fontWeight: '700',
    textAlign: 'center',
    fontSize: '18px',
  }

  return(

  <div>
    <div style={{
      width: '100%',
    }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <h2>REFRACTION</h2>
          <button
            style={{
              fontSize: '2em',
              width: '200px',
              height: '50px',
              textDecoration: 'none',
              border: '1px solid #007bff',
              borderRadius: '5px',
              color: '#007bff',
              background: '#FFF',
              cursor: 'pointer',
            }}
            onClick={() => window.location = '/'}
            >
            Reset form
          </button>
        </div>
          <div>
            <div style={{
               display: 'flex',
            }}>
              <p style={{
                textAlign: 'right',
                width: '80px',
              }}></p>
              <p style={pStyle} >Power</p>
              <p style={pStyle} >Cylinder</p>
              <p style={pStyle} >Axis</p>
            </div>
            <div style={{
               display: 'flex',
            }}>
              <p style={{
                textAlign: 'right',
                width: '80px',
                fontWeight: '700',
                fontSize: '18px',
                margin: '15px 0 0 0',
              }}>OD: </p>
              <input
                style={inputStyle}
                name="powerR"
                value={props.powerR}
                onChange={props.onChange}
              />
              <input
                style={inputStyle}
                name="cylinderR"
                value={props.cylinderR}
                onChange={props.onChange}
              />
              <input
                style={inputStyle}
                name="axisR"
                value={props.axisR}
                onChange={props.onChange}
                type="number"
              />
            </div>
            <div style={{
               display: 'flex',
            }}>
              <p style={{
                textAlign: 'right',
                width: '80px',
                fontWeight: '700',
                fontSize: '18px',
                margin: '15px 0 0 0',
              }}>OS: </p>
              <input
                style={inputStyle}
                name="powerL"
                value={props.powerL}
                onChange={props.onChange}
              />
              <input
                style={inputStyle}
                name="cylinderL"
                value={props.cylinderL}
                onChange={props.onChange}
              />
              <input
                style={inputStyle}
                name="axisL"
                value={props.axisL}
                onChange={props.onChange}
                type="number"
              />

            </div>
            <div style={{
               display: 'flex',
            }}>
              <p style={{
                textAlign: 'right',
                width: '80px',
                fontWeight: '700',
                fontSize: '18px',
                margin: '15px 0 0 0',
              }}>Add: </p>
              <input
                style={inputStyle}
                name="addPower"
                value={props.addPower}
                onChange={props.onChange}
              />
            </div>
            <div style={{
               display: 'flex',
               margin: '50px 0 0 0',
            }}>
              <p style={{
                textAlign: 'right',
                width: '80px',
                fontWeight: '700',
                fontSize: '18px',
                margin: '15px 0 0 0',
              }}>PD (mm): </p>
              <input
                style={inputStyle}
                name="pd"
                value={props.pd}
                onChange={props.onChange}
              />
              <div style={{
                 display: 'flex',
                 margin: '0px 0 0 0',
              }}>
                <p style={{
                  textAlign: 'right',
                  width: '110px',
                  fontWeight: '700',
                  fontSize: '18px',
                  margin: '15px 0 0 38px',
                }}>Vertex (mm): </p>
                <input
                  style={inputStyle}
                  name="vertexDistance"
                  value={props.vertexDistance}
                  onChange={props.onChange}
                />
              </div>
            </div>
          </div>
      </div>
  </div>
  )
}

export default FormInput
